import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";
import Register from "@/pages/Auth/Register.vue";
import Login from "@/pages/Auth/Login.vue";

// Admin pages
const Dashboard = () =>
    import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard.vue");
const Projects = () =>
    import(/* webpackChunkName: "projects" */ "@/pages/Projects.vue");
const Clients = () =>
    import(/* webpackChunkName: "clients" */ "@/pages/Clients.vue");
const Positions = () =>
    import(/* webpackChunkName: "positions" */ "@/pages/Positions.vue");
const Employees = () =>
    import(/* webpackChunkName: "employees" */ "@/pages/Employees.vue");
const Leads = () =>
    import(/* webpackChunkName: "leads" */ "@/pages/Leads.vue");
const LeadsArchive = () =>
    import(/* webpackChunkName: "leads" */ "@/pages/LeadsArchive.vue");
const Notifications = () =>
    import(/* webpackChunkName: "common" */ "@/pages/Notifications.vue");
const Icons = () =>
    import(/* webpackChunkName: "common" */ "@/pages/Icons.vue");
const Maps = () => import(/* webpackChunkName: "common" */ "@/pages/Maps.vue");
const Typography = () =>
    import(/* webpackChunkName: "common" */ "@/pages/Typography.vue");
const TableList = () =>
    import(/* webpackChunkName: "common" */ "@/pages/TableList.vue");
const Profile = () =>
    import(/* webpackChunkName: "profile" */ "@/pages/Profile.vue");
const Costs = () =>
    import(/* webpackChunkName: "costs" */ "@/pages/Costs.vue");
const Statistics = () =>
    import(/* webpackChunkName: "statistics" */ "@/pages/Statistics.vue");

const routes = [
    {
        path: "/register",
        name: "rejestracja",
        component: Register,
    },
    {
        path: "/login",
        name: "logowanie",
        component: Login,
    },
    {
        path: "/",
        component: DashboardLayout,
        meta: { requiresAuth: true },
        redirect: "/login",
        children: [
            {
                path: "dashboard",
                name: "dashboard",
                component: Dashboard,
            },
            {
                path: "costs",
                name: "koszty",
                component: Costs,
            },
            {
                path: "leads",
                name: "leady",
                component: Leads,
            },
            {
                path: "profile",
                name: "profil",
                component: Profile,
            },
            {
                path: "archive",
                name: "archiwum",
                component: LeadsArchive,
            },
            {
                path: "positions",
                name: "stanowiska",
                component: Positions,
            },
            {
                path: "employees",
                name: "pracownicy",
                component: Employees,
            },
            {
                path: "clients",
                name: "klienci",
                component: Clients,
            },
            {
                path: "notifications",
                name: "notifications",
                component: Notifications,
            },
            {
                path: "statistics",
                name: "statystyki",
                component: Statistics,
            },
            {
                path: "icons",
                name: "icons",
                component: Icons,
            },
            {
                path: "maps",
                name: "maps",
                component: Maps,
            },
            {
                path: "typography",
                name: "typography",
                component: Typography,
            },
            {
                path: "table-list",
                name: "table-list",
                component: TableList,
            },
            {
                path: "projects",
                name: "projects",
                component: Projects,
            },
        ],
    },
    { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
