import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from '@/router';

const API_URL = process.env.VUE_APP_API;

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        isAuthenticated: false,
        token: null,
        user: {
            firstName: '',
            lastName: '',
            avatar: ''
        }
    },
    mutations: {
        setAuthentication(state, status) {
            state.isAuthenticated = status;
        },
        setToken(state, token) {
            state.token = token;
        },
        setUserDetails(state, userDetails) {
            state.user.firstName = userDetails.firstName;
            state.user.lastName = userDetails.lastName;
            state.user.avatar = userDetails.avatar;
        },
    },
    actions: {
        async login({ commit }, { credentials }) {
            try {
                const response = await axios.post(`${API_URL}api/login`, credentials);
                const token = response.data.token;
                
                const userDetails = {
                    firstName: response.data.user.firstName,
                    lastName: response.data.user.lastName,
                    avatar: response.data.user.avatar
                };

                console.log('data',response.data)
                console.log('dtl',userDetails)

                localStorage.setItem("userToken", token);
                localStorage.setItem("userDetails", JSON.stringify(userDetails));
                
                commit("setToken", token);
                commit("setUserDetails", userDetails);
                commit("setAuthentication", true);
                router.push("/dashboard");
            } catch (error) {
                console.error("Login error:", error);
                commit("setAuthentication", false);
                throw error;
            }
        },
        logout({ commit }) {
            localStorage.removeItem("userToken");
            localStorage.removeItem("userDetails");
            commit("setToken", null);
            commit("setUserDetails", { firstName: '', lastName: '', avatar: '' });
            commit("setAuthentication", false);
        },
        initializeStore({ commit }) {
            const userDetails = localStorage.getItem('userDetails');
            if (userDetails) {
              const parsedDetails = JSON.parse(userDetails);
              commit('setUserDetails', parsedDetails);
            }
            const token = localStorage.getItem('userToken');
            if (token) {
              commit('setToken', token);
              commit('setAuthentication', true);
            }
        },
    },
});

export default store;