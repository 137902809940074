<template>
    <div class="wrapper">
        <side-bar>
            <template slot="links">
                <sidebar-link
                    to="/dashboard"
                    :name="$t('sidebar.dashboard')"
                    icon="tim-icons icon-chart-pie-36"
                />
                <sidebar-link
                    to="/costs"
                    name="Koszty"
                    icon="tim-icons icon-coins"
                />
                <sidebar-link
                    to="/leads"
                    name="Leady"
                    icon="tim-icons icon-single-02"
                />
                <sidebar-link
                    to="/clients"
                    name="Klienci"
                    icon="tim-icons icon-shape-star"
                />
                <sidebar-link
                    to="/positions"
                    name="Stanowiska"
                    icon="tim-icons icon-app"
                />
                <sidebar-link
                    to="/employees"
                    name="Pracownicy"
                    icon="tim-icons icon-trophy"
                />
                <sidebar-link
                    to="/projects"
                    name="Projekty"
                    icon="tim-icons icon-puzzle-10"
                />
                <sidebar-link
                    to="/statistics"
                    name="Statystyki"
                    icon="tim-icons icon-chart-pie-36"
                />
                <sidebar-link
                    to="/notifications"
                    :name="$t('sidebar.notifications')"
                    icon="tim-icons icon-bell-55"
                />
                <!-- <sidebar-link
                    to="/icons"
                    :name="$t('sidebar.icons')"
                    icon="tim-icons icon-atom"
                />
                <sidebar-link
                    to="/maps"
                    :name="$t('sidebar.maps')"
                    icon="tim-icons icon-pin"
                />
                

                <sidebar-link
                    to="/typography"
                    :name="$t('sidebar.typography')"
                    icon="tim-icons icon-align-center"
                />
                <sidebar-link
                    to="/dashboard?enableRTL=true"
                    :name="$t('sidebar.rtlSupport')"
                    icon="tim-icons icon-world"
                /> -->
            </template>
        </side-bar>
        <div class="main-panel">
            <top-navbar></top-navbar>

            <dashboard-content @click.native="toggleSidebar">
            </dashboard-content>

            <content-footer></content-footer>
        </div>
    </div>
</template>
<style lang="scss"></style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
export default {
    components: {
        TopNavbar,
        ContentFooter,
        DashboardContent,
    },
    methods: {
        toggleSidebar() {
            if (this.$sidebar.showSidebar) {
                this.$sidebar.displaySidebar(false);
            }
        },
    },
};
</script>
