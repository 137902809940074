<template>
    <div class="sidebar" :data="backgroundColor">
        <!--
            Tip 1: you can change the color of the sidebar's background using: data-background-color="white | black | darkblue"
            Tip 2: you can change the color of the active button using the data-active-color="primary | info | success | warning | danger"
        -->
        <div class="sidebar-wrapper">
            <div class="logo">
                <a
                    href="/dashboard"
                    aria-label="sidebar mini logo"
                    class="simple-text logo-mini"
                >
                    <div
                        class="logo-img"
                        :class="{ 'logo-img-rtl': $rtl.isRTL }"
                    >
                        <svg
                            width="40"
                            height="39"
                            viewBox="0 0 40 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="sidebar__logo"
                        >
                            <path
                                d="M24.0725 38.7649C18.0754 38.7649 11.1322 35.2225 8.26038 26.9961C5.9291 21.1704 6.99338 13.8842 10.7268 10.0564C13.2608 7.45412 16.707 6.76579 20.4405 8.10888C20.5925 8.15924 20.6939 8.31034 20.6939 8.46144C20.6939 8.62933 20.5925 8.76364 20.4405 8.814C17.822 9.72059 14.7812 12.9944 14.7981 19.223C14.815 23.4201 16.5381 28.2217 20.3391 29.6151C22.3494 28.6414 23.8867 26.8282 24.7989 24.4106C24.8496 24.2595 25.0017 24.1588 25.1537 24.1588H31.9279H31.9448H39.0738C39.1921 24.1588 39.2934 24.2092 39.361 24.2931C39.4286 24.3771 39.4624 24.4946 39.4455 24.6121C37.9082 32.7714 32.4347 38.1773 25.1537 38.7146C24.7989 38.7481 24.4442 38.7649 24.0725 38.7649ZM16.7915 8.19282C14.2575 8.19282 12.4499 9.38481 11.2674 10.5936C7.71979 14.2199 6.73998 21.1536 8.9699 26.7275V26.7443C11.8756 35.105 19.1735 38.4124 25.103 37.9759C28.3128 37.7408 31.2353 36.4817 33.5835 34.3327C36.0668 32.0495 37.8068 28.8093 38.6177 24.9479H32.1982C31.1339 27.6005 29.3601 29.5144 27.0627 30.4881C24.9003 31.3947 22.4677 31.3779 20.2209 30.421C18.295 29.7662 16.6901 28.272 15.5921 26.1231C14.5954 24.1924 14.0548 21.758 14.0379 19.2733C14.0041 14.2703 16.0144 10.1907 19.2241 8.52859C18.3457 8.29355 17.5348 8.19282 16.7915 8.19282ZM21.2851 29.9845C23.1096 30.5721 25.0355 30.5049 26.7586 29.7662C28.7858 28.91 30.3737 27.2479 31.3705 24.9311H25.4071C24.5118 27.1808 23.1096 28.8932 21.2851 29.9845Z"
                                fill="#F4AF23"
                            />
                            <path
                                d="M20.3731 39C15.5585 39 11.3013 37.7408 7.92268 35.3065C4.23994 32.6539 1.63836 28.6078 0.55719 23.907C-0.50709 19.3069 -0.0340767 14.4886 1.87487 10.3586C3.93586 5.85922 7.43277 2.68616 11.9771 1.1584C13.3117 0.721895 14.5955 0.436488 15.7781 0.335756C15.9808 0.318968 16.1666 0.470065 16.1835 0.688317C16.2004 0.889781 16.0484 1.07446 15.8288 1.09124C14.6969 1.17519 13.4806 1.44381 12.2136 1.88031C7.872 3.34092 4.54401 6.37966 2.5675 10.6776C0.726123 14.6565 0.286896 19.2901 1.3005 23.7391C2.33099 28.2552 4.84809 32.1502 8.3788 34.6853C12.4332 37.6065 17.7884 38.7649 23.8869 38.0262C24.0896 38.0094 24.2923 38.1438 24.3092 38.362C24.343 38.5635 24.191 38.7649 23.9713 38.7817C22.7381 38.9328 21.5387 39 20.3731 39Z"
                                fill="#F4AF23"
                            />
                            <path
                                d="M25.1031 14.8915C25.0862 14.8915 25.0862 14.8915 25.1031 14.8915C24.9342 14.8915 24.799 14.7907 24.7483 14.6396C23.8361 12.2724 22.6367 9.65341 20.0013 8.76361C19.7986 8.69646 19.6972 8.47821 19.7648 8.27674C19.8324 8.07528 20.052 7.97455 20.2547 8.0417C23.1266 9.01544 24.4105 11.6513 25.3734 14.1192C25.5423 14.1192 25.7619 14.1192 26.0491 14.1192C27.2654 14.1024 29.5292 14.0856 31.8098 14.0688C31.2692 9.77093 29.4447 6.2789 26.5052 3.96206C23.5996 1.66202 19.6972 0.621126 15.7949 1.07442C15.5922 1.09121 15.3894 0.956899 15.3725 0.738647C15.3557 0.537183 15.4908 0.335719 15.7104 0.318931C22.0285 -0.419769 26.9276 0.117467 30.6948 1.96422C35.1715 4.16353 38.111 8.3439 39.4286 14.371C39.4793 14.5725 39.3442 14.7739 39.1415 14.8243C39.0739 14.8411 39.0063 14.8411 38.9556 14.8243C38.6009 14.7907 36.0162 14.7739 26.066 14.8747C25.3565 14.8915 25.1538 14.8915 25.1031 14.8915ZM38.6684 14.5557C38.6684 14.5893 38.6853 14.6228 38.7022 14.6396C38.6853 14.6061 38.6853 14.5725 38.6684 14.5557ZM21.8427 0.789012C23.7178 1.30946 25.4748 2.18247 26.9783 3.37446C30.0866 5.84239 32.0294 9.53589 32.57 14.0688C32.9585 14.0688 33.3302 14.0688 33.7018 14.0688C35.3067 14.052 36.5568 14.052 37.4352 14.052C37.9082 14.052 38.2799 14.052 38.5502 14.0688C36.5061 5.3891 31.134 1.09121 21.8427 0.789012Z"
                                fill="#F4AF23"
                            />
                        </svg>
                    </div>
                </a>
                <a
                    href="http://www.creative-tim.com"
                    class="simple-text logo-normal"
                >
                    {{ title }}
                </a>
            </div>
            <slot> </slot>
            <ul class="nav">
                <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
                <slot name="links">
                    <sidebar-link
                        v-for="(link, index) in sidebarLinks"
                        :key="index"
                        :to="link.path"
                        :name="link.name"
                        :icon="link.icon"
                    >
                    </sidebar-link>
                </slot>
            </ul>
        </div>
    </div>
</template>
<script>
import SidebarLink from "./SidebarLink";

export default {
    props: {
        title: {
            type: String,
            default: "Codium",
        },
        backgroundColor: {
            type: String,
            default: "blue",
        },
        activeColor: {
            type: String,
            default: "success",
            validator: (value) => {
                let acceptedValues = [
                    "primary",
                    "info",
                    "success",
                    "warning",
                    "danger",
                ];
                return acceptedValues.indexOf(value) !== -1;
            },
        },
        sidebarLinks: {
            type: Array,
            default: () => [],
        },
        autoClose: {
            type: Boolean,
            default: true,
        },
    },
    provide() {
        return {
            autoClose: this.autoClose,
            addLink: this.addLink,
            removeLink: this.removeLink,
        };
    },
    components: {
        SidebarLink,
    },
    computed: {
        /**
         * Styles to animate the arrow near the current active sidebar link
         * @returns {{transform: string}}
         */
        arrowMovePx() {
            return this.linkHeight * this.activeLinkIndex;
        },
        shortTitle() {
            return this.title
                .split(" ")
                .map((word) => word.charAt(0))
                .join("")
                .toUpperCase();
        },
    },
    data() {
        return {
            linkHeight: 65,
            activeLinkIndex: 0,
            windowWidth: 0,
            isWindows: false,
            hasAutoHeight: false,
            links: [],
        };
    },
    methods: {
        findActiveLink() {
            this.links.forEach((link, index) => {
                if (link.isActive()) {
                    this.activeLinkIndex = index;
                }
            });
        },
        addLink(link) {
            const index = this.$slots.links.indexOf(link.$vnode);
            this.links.splice(index, 0, link);
        },
        removeLink(link) {
            const index = this.links.indexOf(link);
            if (index > -1) {
                this.links.splice(index, 1);
            }
        },
    },
    mounted() {
        this.$watch("$route", this.findActiveLink, {
            immediate: true,
        });
    },
};
</script>

<style lang="scss" scoped>
.sidebar__logo{
    max-width: 20px;
    height: auto;
}

.logo-img{
    background-color: #0c0c0c !important;
}
</style>