import VueRouter from "vue-router";
import routes from "./routes";

const router = new VueRouter({
    mode: "history",
    routes,
    linkExactActiveClass: "active",
    scrollBehavior: (to) => {
        if (to.hash) {
            return { selector: to.hash };
        } else {
            return { x: 0, y: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = localStorage.getItem('userToken');
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const isLoginPage = to.path === '/login';
    const isRegisterPage = to.path === '/register';

    if (isAuthenticated && (isLoginPage || isRegisterPage)) {
        next('/dashboard');
    } else if (requiresAuth && !isAuthenticated) {
        next('/login');
    } else {
        next();
    }
});

export default router;
