<template>
    <div class="register">
        <div class="register__logo">
            <svg width="159" height="39" viewBox="0 0 159 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_3831_1477)">
                <path d="M24.072 38.7648C18.0749 38.7648 11.1318 35.2224 8.25989 26.996C5.92861 21.1703 6.99289 13.8841 10.7263 10.0563C13.2603 7.45402 16.7066 6.76569 20.44 8.10878C20.592 8.15915 20.6934 8.31024 20.6934 8.46134C20.6934 8.62923 20.592 8.76354 20.44 8.8139C17.8215 9.72049 14.7807 12.9943 14.7976 19.2229C14.8145 23.42 16.5376 28.2216 20.3386 29.615C22.3489 28.6413 23.8862 26.8281 24.7985 24.4105C24.8491 24.2594 25.0012 24.1587 25.1532 24.1587H31.9274H31.9443H39.0733C39.1916 24.1587 39.2929 24.2091 39.3605 24.293C39.4281 24.377 39.4619 24.4945 39.445 24.612C37.9077 32.7713 32.4342 38.1772 25.1532 38.7145C24.7985 38.748 24.4437 38.7648 24.072 38.7648ZM16.791 8.19272C14.257 8.19272 12.4494 9.38472 11.2669 10.5935C7.7193 14.2198 6.73949 21.1535 8.96941 26.7274V26.7442C11.8751 35.1049 19.173 38.4123 25.1025 37.9758C28.3123 37.7407 31.2348 36.4816 33.583 34.3326C36.0663 32.0494 37.8063 28.8092 38.6172 24.9478H32.1977C31.1335 27.6004 29.3597 29.5143 27.0622 30.488C24.8998 31.3946 22.4672 31.3778 20.2204 30.4209C18.2945 29.7661 16.6897 28.2719 15.5916 26.123C14.5949 24.1923 14.0543 21.7579 14.0374 19.2732C14.0036 14.2702 16.0139 10.1906 19.2237 8.5285C18.3452 8.29345 17.5343 8.19272 16.791 8.19272ZM21.2846 29.9844C23.1091 30.572 25.035 30.5048 26.7581 29.7661C28.7853 28.9099 30.3733 27.2478 31.37 24.931H25.4066C24.5113 27.1807 23.1091 28.8931 21.2846 29.9844Z" fill="#F4AF23"/>
                <path d="M20.3728 39C15.5582 39 11.301 37.7408 7.92238 35.3065C4.23963 32.6539 1.63806 28.6078 0.556884 23.907C-0.507395 19.3069 -0.0343819 14.4886 1.87456 10.3586C3.93555 5.8592 7.43247 2.68615 11.9768 1.15839C13.3113 0.721883 14.5952 0.436476 15.7778 0.335745C15.9805 0.318956 16.1663 0.470054 16.1832 0.688306C16.2001 0.889769 16.0481 1.07444 15.8285 1.09123C14.6966 1.17518 13.4803 1.44379 12.2133 1.8803C7.8717 3.34091 4.54371 6.37965 2.56719 10.6775C0.725818 14.6564 0.286591 19.2901 1.30019 23.7391C2.33068 28.2552 4.84779 32.1502 8.37849 34.6853C12.4329 37.6065 17.7881 38.7649 23.8866 38.0262C24.0893 38.0094 24.292 38.1437 24.3089 38.362C24.3427 38.5635 24.1906 38.7649 23.971 38.7817C22.7378 38.9328 21.5384 39 20.3728 39Z" fill="#F4AF23"/>
                <path d="M25.1031 14.8915C25.0862 14.8915 25.0862 14.8915 25.1031 14.8915C24.9341 14.8915 24.799 14.7908 24.7483 14.6397C23.8361 12.2725 22.6366 9.65345 20.0013 8.76365C19.7986 8.69649 19.6972 8.47824 19.7648 8.27678C19.8324 8.07531 20.052 7.97458 20.2547 8.04174C23.1266 9.01548 24.4104 11.6513 25.3734 14.1192C25.5423 14.1192 25.7619 14.1192 26.0491 14.1192C27.2654 14.1024 29.5291 14.0856 31.8097 14.0689C31.2691 9.77097 29.4447 6.27893 26.5052 3.9621C23.5996 1.66206 19.6972 0.62116 15.7948 1.07445C15.5921 1.09124 15.3894 0.956933 15.3725 0.738681C15.3556 0.537217 15.4908 0.335754 15.7104 0.318965C22.0285 -0.419735 26.9275 0.117501 30.6948 1.96425C35.1715 4.16356 38.1109 8.34393 39.4286 14.3711C39.4793 14.5725 39.3441 14.774 39.1414 14.8243C39.0739 14.8411 39.0063 14.8411 38.9556 14.8243C38.6008 14.7908 36.0162 14.774 26.066 14.8747C25.3565 14.8915 25.1538 14.8915 25.1031 14.8915ZM38.6684 14.5557C38.6684 14.5893 38.6853 14.6229 38.7022 14.6397C38.6853 14.6061 38.6853 14.5725 38.6684 14.5557ZM21.8427 0.789047C23.7178 1.30949 25.4747 2.1825 26.9782 3.3745C30.0866 5.84243 32.0293 9.53593 32.5699 14.0689C32.9585 14.0689 33.3301 14.0689 33.7018 14.0689C35.3066 14.0521 36.5567 14.0521 37.4352 14.0521C37.9082 14.0521 38.2799 14.0521 38.5502 14.0689C36.5061 5.38913 31.134 1.09124 21.8427 0.789047Z" fill="#F4AF23"/>
                <path d="M54.8186 21.3043C54.8186 16.7378 58.0115 13.4473 62.4544 13.4473C66.0527 13.4473 69.0428 15.6969 69.6003 18.8196L67.2521 19.1386C66.7284 17.04 64.7181 15.613 62.4882 15.613C59.4981 15.613 57.2006 17.9466 57.2344 21.3043C57.2344 24.6789 59.4643 27.0125 62.4544 27.0125C64.7519 27.0125 66.6946 25.6022 67.269 23.554L69.651 23.873C68.9752 26.9621 65.9682 29.1782 62.4544 29.1782C58.0115 29.1782 54.7848 25.8541 54.8186 21.3043Z" fill="white"/>
                <path d="M72.5566 21.3043C72.5566 16.721 75.8171 13.4473 80.4289 13.4473C85.0239 13.4473 88.3012 16.7043 88.3012 21.3043C88.3012 25.9212 85.0239 29.1782 80.4289 29.1782C75.8171 29.1782 72.5566 25.9212 72.5566 21.3043ZM85.8855 21.3043C85.8855 18.0138 83.588 15.613 80.4289 15.613C77.2361 15.613 74.9724 18.0138 74.9724 21.3043C74.9724 24.6285 77.2361 27.0125 80.4289 27.0125C83.588 27.0125 85.8855 24.6117 85.8855 21.3043Z" fill="white"/>
                <path d="M91.2236 21.3045C91.2236 16.738 94.5009 13.4474 98.8087 13.4474C101.157 13.4474 103.1 14.4212 104.181 16.184H104.468V7.38672L106.884 10.7444V28.5908H104.468V26.3411H104.181C103.1 28.1375 101.224 29.1616 98.8425 29.1616C94.5347 29.1784 91.2236 25.8542 91.2236 21.3045ZM104.519 21.3045C104.519 18.0979 102.221 15.6131 99.0959 15.6131C95.9369 15.6131 93.6394 18.0979 93.6394 21.3045C93.6394 24.5615 95.9707 27.0126 99.0959 27.0126C102.221 27.0126 104.519 24.4943 104.519 21.3045Z" fill="white"/>
                <path d="M111.547 14.0181H113.963V28.5906H111.547V14.0181Z" fill="white"/>
                <path d="M131.767 28.5906H129.352V26.5928H129.065C128.051 28.1877 126.395 29.111 124.47 29.111C120.719 29.111 118.371 26.4249 118.371 22.6978V14.0181H120.787V22.6978C120.787 25.1825 122.24 26.9453 124.757 26.9453C127.223 26.9453 129.352 25.065 129.352 22.4963V14.0181H131.767V28.5906Z" fill="white"/>
                <path d="M136.228 14.0183H138.559V15.949H138.846C139.657 14.4548 141.194 13.4307 143.12 13.4307C145.181 13.4307 147.073 14.5555 147.918 16.2512H148.171C149.185 14.5555 150.874 13.4475 153.07 13.4475C156.533 13.4475 159 16.1001 159 19.8775V28.5572H156.584V19.8439C156.584 17.3592 154.979 15.5796 152.783 15.5796C150.435 15.5796 148.779 17.376 148.779 19.8439V28.5572H146.364V19.8439C146.364 17.2753 144.86 15.5796 142.596 15.5796C140.214 15.5796 138.643 17.2753 138.643 19.8439V28.5572H136.228V14.0183V14.0183Z" fill="white"/>
                </g>
                <defs>
                <clipPath id="clip0_3831_1477">
                <rect width="159" height="39" fill="white"/>
                </clipPath>
                </defs>
            </svg>
        </div>
        <card style="width: 400px;">
            <form @submit.prevent="register">
                <div class="form-group">
                    <label for="exampleInputEmail1">Email</label>
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Wprowadź email" v-model="form.email">
                </div>
                <div class="form-group">
                    <label for="exampleInputPassword1">Hasło</label>
                    <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Hasło" v-model="form.password">
                </div>
                <div class="form-group">
                    <label for="exampleInputPassword2">Powtórz hasło</label>
                    <input type="password" class="form-control" id="exampleInputPassword2" placeholder="Powtórz hasło" v-model="form.confirmPassword">
                </div>
                <div class="form-group">
                    <label for="exampleInputFirstName">Imię</label>
                    <input type="text" class="form-control" id="exampleInputFirstName" aria-describedby="emailHelp" placeholder="Wprowadź imię" v-model="form.firstName">
                </div>
                <div class="form-group">
                    <label for="exampleInputLastName">Nazwisko</label>
                    <input type="text" class="form-control" id="exampleInputLastName" aria-describedby="emailHelp" placeholder="Wprowadź nazwisko" v-model="form.lastName">
                </div>
                <div v-if="success">
                    <base-alert type="success">{{ success }}</base-alert>
                </div>
                <div class="register__errors" v-if="errors">
                    <base-alert type="danger" v-for="item in errors">{{ item }}</base-alert>
                </div>
                <button type="submit" class="btn btn-primary">Zarejestruj</button>
            </form>
        </card>
        <router-link class="register__login" to="login">Logowanie</router-link>
    </div>
</template>

<script>
import axios from "axios";
import { BaseAlert } from "@/components";

export default {
    data() {
        return {
            form: {
                email: '',
                password: '',
                confirmPassword: '',
                firstName: '',
                lastName: '',
                loading: false
            },
            errors: [],
            success: false
        };
    },
    components:{
        BaseAlert
    },
    methods: {
        validateForm() {
            this.errors = [];
            if (!this.form.email) {
                this.errors.push('Email jest wymagany.');
                return false;
            }
            if (!this.form.firstName) {
                this.errors.push('Imię jest wymagane.');
                return false;
            }
            if (!this.form.lastName) {
                this.errors.push('Nazwisko jest wymagane.');
                return false;
            }
            if (!this.form.password || this.form.password.length < 8) {
                this.errors.push('Hasło musi mieć co najmniej 8 znaków.');
                return false;
            }
            if (this.form.password !== this.form.confirmPassword) {
                this.errors.push('Hasła nie są takie same.');
                return false;
            }
            return true;
        },

        register() {
            if (this.validateForm()) {
                console.log("Wysyłanie danych do rejestracji");
                this.submitRegistration();
            }
        },

        submitRegistration() {
            axios.post(`${this.$apiUrl}api/register`, {
                email: this.form.email,
                password: this.form.password,
                firstName: this.form.firstName,
                lastName: this.form.lastName,
            })
            .then(response => {
                this.success = response.data.message;
                this.form.email = ''
                this.form.password = ''
                this.form.confirmPassword = ''
                this.form.firstName = ''
                this.form.lastName = ''
            })
            .catch(error => {
                this.errors.push = error;
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.register{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    &__logo{
        margin-bottom: 60px;
    }
    &__login{
        color: #FFF;
    }
}
</style>